// 登录
import service from '@/utils/request'

export function login(data) {
  return service({
    url: '/auth/login',
    method: 'post',
    data
  });
}
// 手机号登录
export function loginTel(data) {
  return service({
    url: '/auth/telephone/login',
    method: 'post',
    data
  });
}
export function getLoginCode(params) {
  return service({
    url: '/user/login/code',
    method: 'get',
    params
  });
}
// 游客登录
export function authLogin(data) {
  return service({
    url: '/auth/tourist/login',
    method: 'post',
    data
  });
}
// 微信登录获取地址
export function weChatLogin(data) {
  return service({
    url: '/wechat/oauth',
    method: 'post',
    data
  });
}
// 微信登录获取appid url
export function weChatInfos(type,params) {
  return service({
    url: `/auth/wecaht/info/${type}`,
    method: 'get',
    params
  });
}
// 微信登录传code
export function weChatCode(params) {
  return service({
    url: '/wechat/callback',
    method: 'get',
    params
  });
}
// 微信登录传codes
export function weChatCodes(params) {
  return service({
    url: '/wechat/callback/code',
    method: 'get',
    params
  });
}
// 根据token获取用户信息
export function getUserInfoByCode(data) {
  return service({
    url: '/auth/info',
    method: 'post',
    data
  });
}