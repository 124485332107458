// 注册
import service from '@/utils/request'
// 注册
export function register(data) {
  return service({
    url: '/user/register',
    method: 'post',
    data
  });
}
// 获取验证码
export function getCodes(params) {
  return service({
    url: '/user/register/code',
    method: 'get',
    params
  });
}