<template>
    <div class="top">
        <div class="left">
            <i class="el-icon-back"></i>
        </div>
        <div class="center">
            <span>111</span>
        </div>
        <div class="right">
            <span>admin</span>
            <i class="el-icon-s-fold"></i>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 751PX) {

    .top {
        width: 100%;
        height: 80px;
        background: var(--custom-color);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1700);
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        box-sizing: border-box;
        // div{
        //   width: 30%;
        //   text-align: center;
        // }

        .left {
            font-weight: 800;
            cursor: pointer;

            i {
                font-weight: 800;
                font-size: 28px;
            }
        }
    }

}

@media screen and (max-width: 750PX) {
    .top {
        padding: 0 10px;
        box-sizing: border-box;
        width: 100%;
        height: 45px;
        background: var(--custom-color);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1700);
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        //  div{
        //   width: 30%;
        //   // text-align: center;
        // }

        .left {
            // line-height: 45px;
            cursor: pointer;

            i {
                font-weight: 800;
                font-size: 28px;
            }
        }

        .right {
            // line-height: 45px;
            display: flex;
            align-items: center;

            span {
                display: inline-block;
                margin-right: 5px;
            }

            i {
                // font-weight: 800;
                font-size: 24px;
            }
        }
    }

}
</style>