import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import  '@/plugins/print.js'
// // 导入flexible
// import 'amfe-flexible'
// // import 'px2rem'
// import './utils/flexible'
// import './utils/px2rem'
import print from './assets/vue-print-nb'
//全局引入echarts
import * as echarts from 'echarts';
// import htmlToPdf from './utils/htmlToPdf'
// Vue.use(htmlToPdf)
 // 在组件中可以调用this.getPdf(title, dom)方法导出
//需要挂载到Vue原型上
Vue.use(print)
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false
Vue.prototype.$print = print;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
