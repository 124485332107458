<template>
    <div class="menu">
        <div class="item" @click="toNext('evaluation')">
            <img src="../assets/home/xlcp.png" alt="">
            <p>心理测评</p>
        </div>
        <div class="item" @click="toNext('report')">
            <img src="../assets/home/cpbg.png" alt="">
            <p>测评报告</p>
        </div>
        <div v-show="settingInfo.isReserve" class="item" @click="toNext('recordManagement')">
            <img src="../assets/home/yyzx.png" alt="">
            <p>预约咨询</p>
        </div>
        <div class="item" @click="toNext('personal')">
            <img src="../assets/home/grzx.png" alt="">
            <p>个人中心</p>
        </div>
        <div class="item" @click="toNext('login')">
            <i class="el-icon-switch-button"></i>
            <p>退出登录</p>
        </div>
    </div>
</template>

<script>
import { timeStamp } from 'console';

import { getSetting } from "@/api/setting";
export default {
    name: 'Home',
    data() {
        return {
            userInfo: null,
            settingInfo:{
        isReserve: false
      }
            // showMenu: false
        }
    },
    methods: {
        toNext(val) {
            console.log(this.$store.state.downLoadUrl)
            if (val === 'login') {
                // this.$router.push('/');
                let path = JSON.parse(localStorage.getItem('location'))
                // console.log(JSON.parse(localStorage.getItem('location')))
                if(path) {
                    window.location.href = path
                } else {
                    window.location.href = `${this.$store.state.downLoadUrl}`
                }
                // 
                localStorage.clear();
                localStorage.setItem('location', JSON.stringify(path));
            } else {
                this.$router.push(`/home/${val}`)
            }

        },
         // 获取配置信息
      async getLogs() {
      const res = await getSetting();
      console.log(res);
      if (res.code === 200) {
        this.settingInfo = res.data;
      }
    },
    },
    created() {
        this.getLogs();
    }
}
</script>
<style lang="scss" scoped>
.menu {
    // right: 0;
    // position: absolute;
    background: white;
    width: 130px;
    // z-index: 1000;
    // box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
    // height: calc(100% - 80px);
    // display: block;

    .item {
        margin: 0px 20px;
        box-sizing: border-box;
        height: 110px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid #DDDDDD;
        cursor: pointer;

        // flex-wrap: wrap;
        img {
            // height: 40px;
            width: 40px;
            // margin: auto;
        }

        i {
            font-size: 40px;
        }

        p {
            // width: 100%;
            // text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: #7D7D7D;
            margin: 0;
        }

        &:nth-child(1) {
            p {
                color: #0097FF;
            }

        }

        &:nth-child(2) {
            p {
                color: #DB7144;
            }

        }
        // &:nth-child(3) {
        //     p {
        //         color: #6F5CC4;
        //     }
        // }
        &:nth-child(3) {
            p {
                color: #C558BE;
            }
        }

          &:nth-child(4) {

            p{
                color: #6F5CC4;
            }
          }
    }
}
</style>
