// 页面配置
import service from '@/utils/request'
// 查看页面配置
export function getSetting(params) {
  return service({
    url: '/user/config',
    method: 'get',
    params
  });
}
// 修改配置
export function updateSetting(data) {
  return service({
    url: '/user/config',
    method: 'post',
    data
  });
}
// 获取组织机构列表
export function getOrganization(params) {
  return service({
    url: '/department/list',
    method: 'get',
    params
  });
}
// 获取页脚信息
export function getFooter(params) {
  return service({
    url: '/footer',
    method: 'get',
    params
  });
}