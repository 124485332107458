import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import {
  getUserInfoByCode
} from '../api/login'
import {
  getSetting
} from '../api/setting'
import {
  Notification,
  Message
} from 'element-ui';
Vue.use(VueRouter)

let info = null;
let code = null;
// console.log(href)
if (window.location.href.indexOf('viewReport') !== -1 || window.location.href.indexOf('nowReport') !== -1) {
  let href = window.location.href.split('=');
  // 区分是微信授权登录还是账号密码登录
  if (href.length > 3) {
    // 微信授权登录
    info = window.location.href.split('=')[4];
  } else {
    info = window.location.href.split('=')[2];
  }

  if (info) {
    localStorage.setItem('token', info.substring(3));
    code = info.substring(3)
  }
} else if (window.location.href.indexOf('appointmentDetails') !== -1) {
  // console.log(window.location.href)
  let href = window.location.href.split('=');
  console.log(href)
  // 区分是微信授权登录还是账号密码登录
  if (href.length > 4) {
    // 微信授权登录
    let indexA = href[1].indexOf('&');
    let indexB = href[2].indexOf('&');
    let indexC = href[3].indexOf('&');
    let indexD = href[4].indexOf('&');
    info = href[5]
    let consultId = href[1].slice(0,indexA);
    let userId = href[2].slice(0,indexB);
    let date = href[3].slice(0,indexC);
    let consultDateId = href[4].slice(0,indexD);
    const consultInfo = {consultId,userId,date,consultDateId}
    // console.log(consultInfo)
    sessionStorage.setItem('consultInfo', JSON.stringify(consultInfo));
    // console.log(href[1].slice(0,indexA))
    // info = window.location.href.split('=')[4];
  } 

  if (info) {
    localStorage.setItem('token', info.substring(3));
    code = info.substring(3)
  }
}



const routes = [{
    path: '/',
    name: 'Login',
    component: Login,
  },
  // { // 配置默认路由
  //   path: "/", // 路由地址
  //   redirect: "/login" // 重定向
  // },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页'
    },
    // 前置导航守卫
    beforeEnter: async (to, from, next) => {
      if (info) {
        // 转换浏览器后获取banner图
        const result = await getSetting();
        console.log(result)
        if (result.code === 200) {
          sessionStorage.setItem('banner', result.bannerLogo)
        }

        let form = new FormData();
        form.append('token', code);
        const res = await getUserInfoByCode(form);
        console.log(res);
        if (res.code === 200) {
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('userInfo', JSON.stringify(res.data));
          localStorage.setItem('roleId', res.data.roleId);
        }
      }
      // 获取本地的 token
      const token = localStorage.token;
      if (token) {
        // 本地有 token
        // const data = await api.users.getLogin({
        //   account: sessionStorage.account,
        //   password: sessionStorage.password,
        // });
        // if (data.code) {
        //   // 如果后端返回了用户信息，可以将用户信息保存在 Vuex 或本地存储中
        //   store.commit("SET_USER_INFO", data.data);
        //   // token 验证有效，允许用户进入权限页面
        next();
        // }
      } else {
        // 本地没有 token
        // ElementUI.Message({
        //   showClose: true,
        //   message: '这是一条消息提示'
        // });
        Message({
          showClose: true,
          message: '你还未登录，请先登录！'
        });
        // alert("你还未登录，请先登录"); // 可以根据项目的 UI 框架来设置弹框提示
        next("/");
      }
    },
    children: [{
        path: 'index',
        name: 'Index',
        component: () => import('@/views/index/Index.vue'),
        meta: {
          title: '首页'
        }
      },
      // 设置页面
      {
        path: 'settings',
        name: 'settings',
        component: () => import('@/views/Settings.vue'),
        meta: {
          title: '配置页面'
        }
      },
      {
        path: 'evaluation',
        name: 'evaluation',
        component: () => import('@/views/evaluation/Evaluation.vue'),
        meta: {
          title: '心理测评'
        }
      },
      // 咨询师给用户注册
      {
        path: 'registerUser',
        name: 'registerUser',
        component: () => import('@/views/evaluation/RegisterUser.vue'),
        meta: {
          title: '心理测评'
        }
      },
      // 量表列表
      {
        path: 'gauge',
        name: 'gauge',
        component: () => import('../views/gauge/Gauge.vue'),
      },
      // 做完题查看报告
      {
        path: 'nowReport',
        name: 'nowReport',
        component: () => import('../views/examination/Report.vue'),
      },
      // 测评计划介绍
      {
        path: 'planDetail',
        name: 'planDetail',
        component: () => import('../views/evaluation/PlanDeatail.vue'),
        meta: {
          title: '计划介绍'
        }
      },
      // 指导语
      {
        path: 'guidelines',
        name: 'guidelines',
        component: () => import('../views/guidelines/Guidelines.vue'),
        // meta: {
        //   title: '指导语'
        // }
      },

      // 测评报告
      {
        path: 'report',
        name: 'report',
        component: () => import('../views/report/Report.vue'),
        meta: {
          title: '测评报告'
        }
      },
      {
        path: 'viewReport',
        name: 'viewReport',
        component: () => import('../views/report/ViewReport.vue'),
        meta: {
          title: '查看报告'
        }
      },
      // 答题记录
      {
        path: 'answerRecord',
        name: 'answerRecord',
        component: () => import('../views/report/AnswerRecord.vue'),
        meta: {
          title: '答题记录'
        }
      },
      // 个人中心首页
      {
        path: 'personal',
        name: 'personal',
        component: () => import('../views/personal/Personal.vue'),
        meta: {
          title: '个人中心'
        }
      },
      // 个人中心-基础信息
      {
        path: 'basicInformation',
        name: 'basicInformation',
        component: () => import('../views/personal/BasicInformation.vue'),
        meta: {
          title: '基础信息'
        }
      },
      // 个人中心-修改手机号
      {
        path: 'changeTel',
        name: 'changeTel',
        component: () => import('../views/personal/ChangeTel.vue'),
        meta: {
          title: '手机号码'
        }
      },
      // 个人中心-密保问题
      {
        path: 'secret',
        name: 'secret',
        component: () => import('../views/personal/Secret.vue'),
        meta: {
          title: '密保问题'
        }
      },
      // 个人中心-修改密码
      {
        path: 'pwd',
        name: 'pwd',
        component: () => import('../views/personal/Password.vue'),
        meta: {
          title: '修改密码'
        }
      },
      // 预约须知
      {
        path: 'record',
        name: 'record',
        component: () => import('../views/record/Record.vue'),
        meta: {
          title: '预约须知'
        }
      },
      // 预约列表
      {
        path: 'consulting',
        name: 'consulting',
        component: () => import('../views/record/Index.vue'),
        meta: {
          title: '预约列表'
        }
      },
      // 咨询师详情列表
      {
        path: 'consultDetails',
        name: 'consultDetails',
        component: () => import('../views/record/AppointmentDetails.vue'),
        meta: {
          title: '咨询师详情'
        }
      },
      // // 预约成功
      // {
      //   path: 'appointmentSuccess',
      //   name: 'appointmentSuccess',
      //   component: () => import('../views/record/Success.vue'),
      //   meta: {
      //     title: '预约成功'
      //   }
      // },
      // 预约详情
      {
        path: 'appointmentDetails',
        name: 'appointmentDetails',
        component: () => import('../views/record/Detail.vue'),
        meta: {
          title: '预约详情'
        }
      },
      // 预约管理
      {
        path: 'recordManagement',
        name: 'recordManagement',
        component: () => import('../views/record/RecordManagement.vue'),
        meta: {
          title: '预约管理'
        }
      },
      // 我的预约
      {
        path: 'myRecord',
        name: 'myRecord',
        component: () => import('../views/record/MyRecord.vue'),
        meta: {
          title: '我的预约'
        }
      },
      // // 信息填写
      // {
      //   path: 'information',
      //   name: 'information',
      //   component: () => import('@/views/record/UserRegister.vue'),
      //   meta: {
      //     title: '信息填写'
      //   }
      // },
      // // 信息填写
      // {
      //   path: 'reportList',
      //   name: 'reportList',
      //   component: () => import('@/views/record/ReportList.vue'),
      //   meta: {
      //     title: '报告列表'
      //   }
      // }
    ]
  },
  // 开始做题
  {
    path: '/examination',
    name: 'examination',
    component: () => import('../views/examination/Examination.vue'),
  },
  // 忘记密码
  {
    path: '/forgetPwd',
    name: 'forgetPwd',
    component: () => import('../views/ForgetPassword'),
  },
  // 手机验证
  {
    path: '/verification',
    name: 'verification',
    component: () => import('../views/AccountVerification'),
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router