<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style lang="scss">
@import './assets/js/style.scss';
#app {
  font-family: PingFangSC-Semibold, PingFang SC;
  /* font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}

body {
  margin: 0;
  padding: 0;
  // font-size: 28px;
  // .span{
  //   font-size: 26px;
  // }
  .el-message-box.el-message-box--center{
    width: 300px;
}
.el-message-box{
  // margin-top: 100px !important;
  width: 350px !important;
}

  // 设置tabs的样式

  .el-tabs__item {
    font-size: 18px !important;
    color: var(--custom-color);
    font-weight: 600;
  }

  .el-tabs__nav-wrap::after {
    background-color: transparent;
    
  }

  .el-tabs__active-bar {
    height: 8px;
    border-radius: 3px;
    
    // margin-top: 5px;
    // display: block;
  }
    .el-tabs__nav{
    height: 45px;
  }

  // 输入框和按钮的高度
  .el-input__inner,
  .el-button {
    height: 45px;
   
    // display: flex;
    // align-items: center;
    // line-height: 55px;
    // font-size: 18px;
  }
  .el-button{
    font-size: 16px;
  }

  // // 取消数字输入框后的上下减
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .el-input {
    font-size: 14px;
  }


}
</style>
